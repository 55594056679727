exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingredient-js": () => import("./../../../src/pages/ingredient.js" /* webpackChunkName: "component---src-pages-ingredient-js" */),
  "component---src-pages-separately-js": () => import("./../../../src/pages/separately.js" /* webpackChunkName: "component---src-pages-separately-js" */),
  "component---src-pages-weight-js": () => import("./../../../src/pages/weight.js" /* webpackChunkName: "component---src-pages-weight-js" */),
  "component---src-pages-white-tights-news-js": () => import("./../../../src/pages/whiteTightsNews.js" /* webpackChunkName: "component---src-pages-white-tights-news-js" */),
  "component---src-templates-notice-details-js": () => import("./../../../src/templates/noticeDetails.js" /* webpackChunkName: "component---src-templates-notice-details-js" */),
  "component---src-templates-post-details-js": () => import("./../../../src/templates/postDetails.js" /* webpackChunkName: "component---src-templates-post-details-js" */)
}

